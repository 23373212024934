import { fetchGet, fetchPost } from '../index';

//! 获取claim detial详细数据
const getClaimDetail = (id: string): Promise<any> => {
    return fetchGet(`/claimapi/delay/sipo/detailList/${id}`);
};

//! detail页提交
const submitClaim = (params: any): Promise<any> => {
    return fetchPost(`/claimapi/delay/sipo/detailSubmit`, params);
};

//! 获取 dealerInfo
const getDealerInfo = (id: string): Promise<any> => {
    return fetchGet(`/dapi/noAuth/dealer/${id}`);
};

export default {
    getClaimDetail,
    submitClaim,
    getDealerInfo,
};
