
import {
    ref,
    defineComponent,
    onMounted,
    watch,
    reactive,
} from '@vue/runtime-core';
import ClaimDetailAPI from '@/API/claimDelay/claimDetail';

export default defineComponent({
    name: 'dealer-info-drawer',
    props: {
        visible: {
            type: Boolean,
            required: true,
            default: false,
        },
        dealerCode: {
            type: String,
            required: true,
            default: '',
        },
    },
    emits: ['close'],
    setup(props, { emit }) {
        // const dealerInfos = {
        //     'Dealer Code': '019',
        //     'MBAFC Code': '123',
        //     'SWT Code': '123',
        //     'NO Code': '019',
        //     Group: '123',
        // };
        const dealerInfos = ref<any>({ dealerCode: '' });

        const getDealerInfo = () => {
            ClaimDetailAPI.getDealerInfo(props.dealerCode).then((res: any) => {
                dealerInfos.value = res[0].dealerInfo;
                console.log(dealerInfos.value);
            });
        };

        const close = () => {
            emit('close');
        };

        watch(
            () => props.dealerCode,
            () => {
                getDealerInfo();
            }
        );

        // onMounted(() => {
        //     getDealerInfo();
        // });

        return {
            dealerInfos,
            close,
        };
    },
});
